import { AppConfig } from '@/config';
import axios, { AxiosInstance } from 'axios';

interface CreateAxiosInstanceProps {
  onRefreshTokenInvalid: () => void;
}

export const createAxiosInstance = (
  props: CreateAxiosInstanceProps,
  getToken: () => Promise<string | null>
): AxiosInstance => {
  const axiosInstance = axios.create({
    baseURL: AppConfig.ENV.API_BASE_URL,
    // timeout: 10000,
  });

  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = await getToken();
      if (token) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const token = await getToken();
        if (token) {
          return axiosInstance(originalRequest);
        }
        if (!token) {
          props.onRefreshTokenInvalid();
        }
        return Promise.reject(error);
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};
