import { AppConfig } from '@/config';
import { ReactNode } from 'react';

export interface NotificationProps {
  duration?: number; // In Ms
  key?: string;
  icon?: ReactNode;
  title: string;
  description: string;
  placement: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight';
}

export interface NotificationDetail extends NotificationProps {
  type?: 'success' | 'error' | 'info' | 'warning';
}

export const NotificationRequestEventName = `${AppConfig.CONSTANTS.APP_NAME_KEY}_notificationRequestEvent`;

export const NotificationDestroyEventName = `${AppConfig.CONSTANTS.APP_NAME_KEY}_notificationDestroyEvent`;

function dispatchToastRequestEvent(detail: NotificationDetail) {
  return document.dispatchEvent(
    new CustomEvent(NotificationRequestEventName, {
      detail,
    })
  );
}

function dispatchToastDestroyEvent(key: string) {
  return document.dispatchEvent(
    new CustomEvent(NotificationDestroyEventName, {
      detail: {
        key,
      },
    })
  );
}

export const notification = {
  success: (props: NotificationProps) => {
    dispatchToastRequestEvent({
      ...props,
      type: 'success',
    });
  },
  error: (props: NotificationProps) => {
    dispatchToastRequestEvent({
      ...props,
      type: 'error',
    });
  },
  info: (props: NotificationProps) => {
    dispatchToastRequestEvent({
      ...props,
      type: 'info',
    });
  },
  warning: (props: NotificationProps) => {
    dispatchToastRequestEvent({
      ...props,
      type: 'warning',
    });
  },
  open: (props: NotificationDetail) => {
    dispatchToastRequestEvent({
      ...props,
    });
  },
  dismiss: (key: string) => {
    dispatchToastDestroyEvent(key);
  },
};
