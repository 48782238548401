import { useCallback, useEffect } from 'react';
import { notification } from 'antd';
import {
  NotificationDestroyEventName,
  NotificationDetail,
  NotificationRequestEventName,
} from './NotificationEvent';

export const useNotification = () => {
  const [notify, contextHolder] = notification.useNotification();

  return {
    notify,
    notificationContextHolder: contextHolder,
  };
};

export const NotificationHolder = () => {
  const [api, contextHolder] = notification.useNotification();

  const summonToast = useCallback(
    (detail: NotificationDetail) => {
      api.open({
        message: detail.title,
        description: detail.description,
        duration: detail.duration ? detail.duration / 1000 : undefined,
        type: detail.type,
        key: detail.key,
        icon: detail.icon,
      });
    },
    [api]
  );

  const destroyToast = useCallback(
    (key: string) => {
      api.destroy(key);
    },
    [api]
  );

  useEffect(() => {
    const handleSummonMessage = ((event: CustomEvent<NotificationDetail>) => {
      summonToast(event.detail);
    }) as EventListener;

    const handleDestroyMessage = ((event: CustomEvent<string>) => {
      destroyToast(event.detail);
    }) as EventListener;

    document.addEventListener(
      NotificationRequestEventName,
      handleSummonMessage
    );
    document.addEventListener(
      NotificationDestroyEventName,
      handleDestroyMessage
    );

    return () => {
      document.removeEventListener(
        NotificationRequestEventName,
        handleSummonMessage
      );
      document.removeEventListener(
        NotificationDestroyEventName,
        handleDestroyMessage
      );
    };
  }, []);

  return contextHolder;
};
