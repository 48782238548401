import { getAuth, signOut as SignOutUser } from 'firebase/auth';
import firebase_app from '../config';

const auth = getAuth(firebase_app);

export default async function signOut() {
  let result = null;
  let error: any = null;
  try {
    result = await SignOutUser(auth);
  } catch (e) {
    error = e;
  }

  return { result, error };
}
