import { User } from '@/core/domain/entities';
import {
  generateDefaultExtraReducer,
  generateDefaultSliceReducer,
} from '@/core/lib/redux/slice';
import { PermissionType, ReduxStateKey } from '@/types';
import { createSlice } from '@reduxjs/toolkit';

export type AuthSliceState = {
  user: User | null;
  permissions: PermissionType[];
};

const initialState: AuthSliceState = {
  user: null,
  permissions: [],
};

export const authSlice = createSlice({
  name: ReduxStateKey.Auth,
  initialState,
  reducers: {
    ...generateDefaultSliceReducer<AuthSliceState>(initialState),
  },
  extraReducers: (builder) => {
    generateDefaultExtraReducer<AuthSliceState>(builder);
  },
});
