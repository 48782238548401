import { PermissionRequirements, PermissionType } from '@/types';
import { useEffect, useState } from 'react';
import { useSelector } from '../redux/hooks';

// eslint-disable-next-line arrow-body-style
export const getPermissions = (): PermissionType[] | null => {
  // TODO: implement this function
  return [];
};

// first dimension is AND, second dimension is OR
export const checkRequiredPermissions = (
  permissions: PermissionType[],
  requiredPermissions: PermissionRequirements
) => {
  for (const reqPermItem of requiredPermissions) {
    if (Array.isArray(requiredPermissions)) {
      for (const reqPermItem of requiredPermissions) {
        if (!permissions.includes(reqPermItem as PermissionType)) {
          return false;
        }
      }
    } else if (!permissions.includes(reqPermItem as PermissionType)) {
      return false;
    }
  }
  return true;
};

export const useRequiredPermissions = (
  permissions: PermissionRequirements,
  options: {
    onRejected?: () => void;
    onUnauthenticated?: () => void;
    network?: boolean;
  } = {}
) => {
  const { permissions: authPermissions, user } = useSelector(
    (state) => state.auth
  );
  const [authorizationStatus, setAuthorizeStatus] = useState<
    'loading' | 'ok' | 'rejected' | 'none'
  >('loading');

  useEffect(() => {
    (async () => {
      if (!user) {
        setAuthorizeStatus('none');
        options.onUnauthenticated?.();
        return;
      }
      if (permissions.length === 0) {
        setAuthorizeStatus('ok');
        return;
      }
      if (options.network) {
        const networkPermissions = getPermissions();
        if (networkPermissions) {
          setAuthorizeStatus(
            checkRequiredPermissions(networkPermissions, permissions)
              ? 'ok'
              : 'rejected'
          );
          return;
        }
      }
      setAuthorizeStatus(
        checkRequiredPermissions(authPermissions, permissions)
          ? 'ok'
          : 'rejected'
      );
    })();
  }, [authPermissions, user]);

  useEffect(() => {
    if (authorizationStatus === 'rejected') {
      options.onRejected?.();
    }
  }, [authorizationStatus]);

  return authorizationStatus;
};
