import {
  generateDefaultExtraReducer,
  generateDefaultSliceReducer,
} from '@/core/lib/redux/slice';
import { LanguageData, languageList } from '@/i18n/language';
import { ReduxStateKey, ThemeType } from '@/types';
import { createSlice } from '@reduxjs/toolkit';

export type AppSettingsSliceState = {
  theme: ThemeType;
  language: LanguageData;
  sidebarOpened: boolean;
};

const initialState: AppSettingsSliceState = {
  theme: 'light',
  language: languageList.en,
  sidebarOpened: false,
};

export const appSettingsSlice = createSlice({
  name: ReduxStateKey.AppSettings,
  initialState,
  reducers: {
    ...generateDefaultSliceReducer<AppSettingsSliceState>(initialState),
  },
  extraReducers: (builder) => {
    generateDefaultExtraReducer<AppSettingsSliceState>(builder);
  },
});
