import { AppConfig } from '@/config';
import { ReactNode } from 'react';

export interface ToastProps {
  message: string;
  duration?: number; // In Ms
  key?: string;
  icon?: ReactNode;
}

export interface ToastDetail extends ToastProps {
  type?: 'success' | 'error' | 'loading' | 'info' | 'warning';
}

export const ToastRequestEventName = `${AppConfig.CONSTANTS.APP_NAME_KEY}_toastRequestEvent`;

export const ToastDestroyEventName = `${AppConfig.CONSTANTS.APP_NAME_KEY}_toastDestroyEvent`;

function dispatchToastRequestEvent(detail: ToastDetail) {
  return document.dispatchEvent(
    new CustomEvent(ToastRequestEventName, {
      detail,
    })
  );
}

function dispatchToastDestroyEvent(key: string) {
  return document.dispatchEvent(
    new CustomEvent(ToastDestroyEventName, {
      detail: {
        key,
      },
    })
  );
}

export const toast = {
  success: (props: ToastProps) => {
    dispatchToastRequestEvent({
      ...props,
      type: 'success',
    });
  },
  error: (props: ToastProps) => {
    dispatchToastRequestEvent({
      ...props,
      type: 'error',
    });
  },
  loading: (props: ToastProps) => {
    dispatchToastRequestEvent({
      ...props,
      type: 'loading',
    });
  },
  info: (props: ToastProps) => {
    dispatchToastRequestEvent({
      ...props,
      type: 'info',
    });
  },
  warning: (props: ToastProps) => {
    dispatchToastRequestEvent({
      ...props,
      type: 'warning',
    });
  },
  open: (props: ToastDetail) => {
    dispatchToastRequestEvent({
      ...props,
    });
  },
  dismiss: (key: string) => {
    dispatchToastDestroyEvent(key);
  },
};
