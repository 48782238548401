import { useCallback, useEffect } from 'react';
import { message } from 'antd';
import {
  ToastDestroyEventName,
  ToastDetail,
  ToastRequestEventName,
} from './ToastEvent';

export const useToast = () => {
  const [messageApi, contextHolder] = message.useMessage();

  return {
    toast: messageApi,
    toastContextHolder: contextHolder,
  };
};

export const ToastHolder = () => {
  const [messageApi, contextHolder] = message.useMessage();

  const summonToast = useCallback(
    (detail: ToastDetail) => {
      messageApi.open({
        content: detail.message,
        duration: detail.duration ? detail.duration / 1000 : undefined,
        type: detail.type,
        key: detail.key,
        icon: detail.icon,
      });
    },
    [messageApi]
  );

  const destroyToast = useCallback(
    (key: string) => {
      messageApi.destroy(key);
    },
    [messageApi]
  );

  useEffect(() => {
    const handleSummonMessage = ((event: CustomEvent<ToastDetail>) => {
      summonToast(event.detail);
    }) as EventListener;

    const handleDestroyMessage = ((event: CustomEvent<string>) => {
      destroyToast(event.detail);
    }) as EventListener;

    document.addEventListener(ToastRequestEventName, handleSummonMessage);
    document.addEventListener(ToastDestroyEventName, handleDestroyMessage);

    return () => {
      document.removeEventListener(ToastRequestEventName, handleSummonMessage);
      document.removeEventListener(ToastDestroyEventName, handleDestroyMessage);
    };
  }, []);

  return contextHolder;
};
