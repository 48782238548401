import React from 'react';

function Logo() {
  return (
    <div className="h-16 w-full p-3">
      <div className="flex h-full w-full items-center justify-center rounded bg-white opacity-60">
        Ini Logo
      </div>
    </div>
  );
}

export default Logo;
