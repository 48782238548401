// Import the functions you need from the SDKs you need
import { getApps, initializeApp } from 'firebase/app';
import { getAuth } from '@firebase/auth';
import { AppConfig } from '@/config';

const firebaseConfig = {
  apiKey: AppConfig.ENV.API_KEY_FIREBASE,
  authDomain: AppConfig.ENV.AUTH_DOMAIN_FIREBASE,
};
// Initialize Firebase
const firebase_app =
  getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];

export default firebase_app;
export const auth = getAuth(firebase_app);
