import { usePageTracking } from '@/core/lib/analytics/google-analytics';
import { Layout as AntdLayout, Breadcrumb } from 'antd';
import React, { useMemo, useEffect } from 'react';
import Link from 'next/link';
import { NextPageRoute } from '@/config';
import { ToastHolder } from '@/core/lib/feedback-component/toast';
import { NotificationHolder } from '@/core/lib/feedback-component/notification';
import { useRequiredPermissions } from '@/core/lib/security';
import { PermissionRequirements } from '@/types';
import { usePathname, useRouter } from 'next/navigation';
import {
  BreadcrumbItemType,
  BreadcrumbSeparatorType,
} from 'antd/es/breadcrumb/Breadcrumb';
import Title from 'antd/es/typography/Title';
import QueryString from 'qs';
import { useAuthContext } from '@/shared/contexts/auth-context';
import { Sidebar } from './Sidebar';
import Topbar from './Topbar';

const { Content, Footer } = AntdLayout;

interface BreadcrumbItem {
  name: string;
  path?: string;
}

interface ILayoutProps {
  title?: string;
  breadcrumbs?: BreadcrumbItem[];
  requiredPermissions?: PermissionRequirements;
  children: React.ReactNode;
}

type BreadcrumbItemAntD = Partial<BreadcrumbItemType & BreadcrumbSeparatorType>;

const itemRender = (item: BreadcrumbItemAntD) =>
  item.path ? (
    <Link href={item.path}>{item.title}</Link>
  ) : (
    <span>{item.title}</span>
  );

export const Layout: React.FC<ILayoutProps> = ({
  title,
  children,
  breadcrumbs,
  requiredPermissions,
}) => {
  const router = useRouter();
  const path = usePathname();
  const contextValue = useAuthContext(); // Get the context value

  // Check if the context value is not null before destructuring
  const { user } = contextValue || { user: null };

  const authorizationStatus = useRequiredPermissions(
    requiredPermissions || [],
    {
      onUnauthenticated() {
        const params = QueryString.stringify({
          redirect: path,
        });
        router.replace(`${NextPageRoute.Login}?${params}`);
      },
    }
  );

  usePageTracking();

  const breadcrumbsMemoized = useMemo<BreadcrumbItemAntD[]>(() => {
    if (breadcrumbs && breadcrumbs.length > 0) {
      return breadcrumbs.map((breadcrumb) => ({
        key: breadcrumb.name + breadcrumb.path,
        path: breadcrumb.path,
        title: breadcrumb.name,
      }));
    }
    return [];
  }, [breadcrumbs]);

  useEffect(() => {
    if (!user) {
      router.push(NextPageRoute.Login);
    }
  }, [user]);

  const content = useMemo(() => {
    if (authorizationStatus === 'loading' || authorizationStatus === 'none') {
      return <>Loading...</>;
    }
    if (!user || authorizationStatus === 'rejected') {
      router.push(NextPageRoute.Login);
    }
    return (
      <>
        <Sidebar />
        <AntdLayout className="site-layout">
          <Topbar />
          <Content
            style={{
              margin: '0 1.5rem',
            }}
          >
            {title && (
              <Title className="mt-3" level={2}>
                {title}
              </Title>
            )}
            {breadcrumbs && breadcrumbs.length > 0 && (
              <Breadcrumb
                style={{ margin: '16px 0' }}
                items={breadcrumbsMemoized}
                itemRender={itemRender}
              />
            )}
            {children}
            <Footer
              style={{
                textAlign: 'center',
              }}
            >
              MetaPals ©2024
            </Footer>
          </Content>
        </AntdLayout>
      </>
    );
  }, [title, authorizationStatus, children]);

  return (
    <AntdLayout style={{ minHeight: '100vh' }}>
      {content}
      <ToastHolder />
      <NotificationHolder />
    </AntdLayout>
  );
};
