import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import firebase_app from '../config';

const auth = getAuth(firebase_app);

const provider = new GoogleAuthProvider();

export default async function signInWithGoogle() {
  let result = null;
  let error: any = null;
  try {
    result = await signInWithPopup(auth, provider);
  } catch (e) {
    error = e;
  }

  return { result, error };
}
