import { useSelector as useSelectorOrigin } from 'react-redux';
import { ReduxStateKey } from '@/types';
import { ReduxStoreState } from '../ReduxState';

export function useKeySelector<K extends ReduxStateKey>(key: K) {
  return useSelectorOrigin((state: ReduxStoreState) => state[key]);
}

export function useSelector<T>(selector: (state: ReduxStoreState) => T) {
  return useSelectorOrigin(selector);
}
