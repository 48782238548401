/* eslint-disable no-param-reassign */

import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

export function generateDefaultSliceReducer<T>(initialState: T) {
  return {
    reset: (state: T, action: PayloadAction<void>) => {
      state = initialState;
      return state;
    },
    update: (state: T, action: PayloadAction<Partial<T>>) => {
      state = { ...state, ...action.payload } as T;
      return state;
    },
    replace: (state: T, action: PayloadAction<T>) => {
      state = action.payload as T;
      return state;
    },
  };
}

export function generateDefaultExtraReducer<T>(
  builder: ActionReducerMapBuilder<T>
) {
  return {};
}
