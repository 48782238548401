import { NextApiRoute, NextPageRoute } from '@/config';
import { signOut } from '@/core/lib/firebase';
import { useSelector } from '@/core/lib/redux/hooks';
import { LogoutOutlined } from '@ant-design/icons';
import { Layout as AntdLayout, Avatar, Dropdown } from 'antd';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { useRouter } from 'next/navigation';
import { useMemo } from 'react';

const Topbar = () => {
  const router = useRouter();
  const user = useSelector((state) => state.auth.user);

  const handleLogOut = async () => {
    signOut().then(() => {
      router.push(NextPageRoute.Login);
    });
  };

  const dropdownMenuItem = useMemo<ItemType[]>(
    () => [
      {
        key: NextApiRoute.Logout,
        label: 'Logout',
        icon: <LogoutOutlined rev />,
        onClick: () => handleLogOut(),
      },
    ],
    [router]
  );

  const menuItems = useMemo(
    () =>
      user && (
        <>
          <div className="text-white">{user.name}</div>
          <Dropdown
            menu={{
              items: dropdownMenuItem,
            }}
          >
            <Avatar
              style={{
                backgroundColor: '#f56a00',
                verticalAlign: 'middle',
              }}
              size="large"
              className="cursor-pointer"
            >
              {user.name?.charAt(0)}
            </Avatar>
          </Dropdown>
        </>
      ),
    [user, dropdownMenuItem]
  );

  return (
    <AntdLayout.Header
      style={{
        padding: 0,
      }}
      className="flex items-center justify-between"
    >
      <div />
      <div className="mr-5 flex h-full items-center gap-3">{menuItems}</div>
    </AntdLayout.Header>
  );
};

export default Topbar;
