import { useSelector } from '@/core/lib/redux/hooks';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { appSettingsSlice } from '../states';

export const useSidebarCollapsible = () => {
  const collapsed = useSelector(
    (state) => !state.appSettings?.sidebarOpened ?? false
  );
  const dispatch = useDispatch();

  const setCollapsed = useCallback((newCollapsed: boolean) => {
    dispatch(
      appSettingsSlice.actions.update({
        sidebarOpened: !newCollapsed,
      })
    );
  }, []);

  return {
    collapsed,
    setCollapsed,
  };
};
