import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { AppConfig } from '@/config';
import { usePathname } from 'next/navigation';

export const initGoogleAnalytics = () => {
  if (AppConfig.ENV.GA4_MEASUREMENT_ID) {
    ReactGA.initialize(AppConfig.ENV.GA4_MEASUREMENT_ID);
  }
};

export const usePageTracking = () => {
  const pathname = usePathname();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized) {
      ReactGA.set({ page: pathname });
    }
  }, [initialized, pathname]);
};
