import { Layout as AntdLayout, Menu, MenuProps } from 'antd';
import {
  DesktopOutlined,
  FileOutlined,
  PieChartOutlined,
  SettingOutlined,
  TeamOutlined,
  ToolOutlined,
  UserOutlined,
  MailOutlined,
  CalendarOutlined,
  GiftOutlined,
  TagOutlined,
  InboxOutlined,
  NotificationOutlined,
} from '@ant-design/icons';
import { useSidebarCollapsible } from '@/core/app/usecases/layout';
import { usePathname, useRouter } from 'next/navigation';
import { useMemo } from 'react';
import { NextPageRoute } from '@/config';
import { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime';
import Logo from '../Logo';

type MenuItem = Required<MenuProps>['items'][number];

const menuItems = (router: AppRouterInstance): MenuItem[] => [
  {
    key: NextPageRoute.Dashboard,
    label: 'Dashboard',
    icon: <PieChartOutlined rev />,
    onClick: () => router.push(NextPageRoute.Dashboard),
  },
  {
    key: '/tools',
    label: 'Tools',
    icon: <ToolOutlined rev />,
    children: [
      {
        key: NextPageRoute.Tools.KennelSkinEditor,
        label: 'Kennel Skin Editor',
        icon: <DesktopOutlined rev />,
        onClick: () => router.push(NextPageRoute.Tools.KennelSkinEditor),
      },
      {
        key: NextPageRoute.Tools.PetFrameworkDebugger,
        label: 'Pet Framework',
        icon: <DesktopOutlined rev />,
        onClick: () => router.push(NextPageRoute.Tools.PetFrameworkDebugger),
      },
    ],
  },
  {
    key: '/users',
    label: 'Users',
    icon: <UserOutlined rev />,
    onClick: () => router.push(NextPageRoute.Users),
  },
  {
    key: '/mail',
    label: 'Mail',
    icon: <MailOutlined rev />,
    children: [
      {
        key: '/messages',
        label: 'Messages',
        icon: <InboxOutlined rev />,
        onClick: () => router.push(NextPageRoute.Mail.Messages),
      },
      {
        key: '/owed-delivery',
        label: 'Owed Delivery',
        icon: <GiftOutlined rev />,
        onClick: () => router.push(NextPageRoute.Mail.OwedDelivery),
      },
      {
        key: '/announcements',
        label: 'Announcements',
        icon: <NotificationOutlined rev />,
        onClick: () => router.push(NextPageRoute.Mail.Announcements),
      },
    ],
  },
  {
    key: '/coupon-code',
    label: 'Coupon Code',
    icon: <TagOutlined rev />,
    onClick: () => router.push(NextPageRoute.CouponCode),
  },
  {
    key: '/routines',
    label: 'Routines',
    icon: <CalendarOutlined rev />,
    children: [
      {
        key: NextPageRoute.Routines.DailyRewards,
        label: 'Daily Rewards',
        icon: <DesktopOutlined rev />,
        onClick: () => router.push(NextPageRoute.Routines.DailyRewards),
      },
      {
        key: NextPageRoute.Routines.LoginStreakRewards,
        label: 'Login Streak Rewards',
        icon: <DesktopOutlined rev />,
        onClick: () => router.push(NextPageRoute.Routines.LoginStreakRewards),
      },
      {
        key: NextPageRoute.Routines.MonthlyRewards,
        label: 'Monthly Rewards',
        icon: <DesktopOutlined rev />,
        onClick: () => router.push(NextPageRoute.Routines.MonthlyRewards),
      },
    ],
  },
  {
    key: NextPageRoute.Settings,
    label: 'Settings',
    icon: <SettingOutlined rev />,
    onClick: () => router.push(NextPageRoute.Settings),
  },
];

export const Sidebar: React.FC = () => {
  const router = useRouter();
  const path = usePathname();
  const { collapsed, setCollapsed } = useSidebarCollapsible();

  const menuItemsMemoized = useMemo(() => menuItems(router), [router]);

  return (
    <AntdLayout.Sider
      collapsible
      collapsed={collapsed}
      breakpoint="lg"
      onCollapse={(value) => setCollapsed(value)}
    >
      <Logo />
      <Menu
        theme="dark"
        selectedKeys={[path]}
        mode="inline"
        items={menuItemsMemoized}
      />
    </AntdLayout.Sider>
  );
};
