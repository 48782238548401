import { User } from '@firebase/auth';
import { message } from 'antd';
import signInWithGoogle from './signInWithGoogle';

export async function handleSignInWithGoogle(
  onSuccess: () => void,
  onError: (error: string) => void,
  cb?: (user: User) => any
) {
  const { result, error } = await signInWithGoogle();
  if (!error) {
    const user = result?.user;
    if (!user) return;

    // callback
    if (cb) cb(user);

    // onSuccess
    onSuccess();
  } else {
    if (
      error.code === 'auth/cancelled-popup-request' ||
      error.code === 'auth/popup-closed-by-user'
    ) {
      // onError
      message.error('Sign in with Google is cancelled');
      return;
    }
    // onError
    message.error(error.message);
  }
}
