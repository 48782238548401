'use client';

import {
  useContext,
  createContext,
  useState,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useDispatch } from 'react-redux';
import { authSlice } from '@/core/app/states';
import { onAuthStateChanged, User } from '@firebase/auth';
import { useRouter } from 'next/navigation';
import { NextPageRoute } from '@/config';
import { createAxiosInstance } from '@/core/lib/axios';
import axios, { AxiosInstance } from 'axios';
import { auth } from '../../core/lib/firebase/config';

export interface IAuthStateContext {
  user: User | null;
  getToken: () => Promise<string | null>;
  axiosClient: AxiosInstance;
}

const AuthContext = createContext<IAuthStateContext>({
  user: null,
  getToken: async () => {
    throw new Error('not implemented');
  },
  axiosClient: axios,
});

export const AuthContextProvider = ({ children }: { children: any }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const router = useRouter();

  const getToken = useRef(async () => {
    if (user) {
      return user.getIdToken();
    }
    return null;
  });

  useEffect(() => {
    getToken.current = async () => {
      if (user) {
        return user.getIdToken();
      }
      return null;
    };
  }, [user]);

  useEffect(() => {
    console.log('User : ', user);
    console.log('Token : ', getToken.current());
  }, [user]);

  const onRefreshTokenInvalid = useRef(() => {
    if (!user) return;
    setUser(null);
    dispatch(
      authSlice.actions.update({
        user: null,
      })
    );
    router.push(NextPageRoute.Login);
  });

  useEffect(() => {
    onRefreshTokenInvalid.current = () => {
      if (!user) return;
      setUser(null);
      dispatch(
        authSlice.actions.update({
          user: null,
        })
      );
      router.push(NextPageRoute.Login);
    };
  }, [user]);

  const axiosClient = useRef(
    createAxiosInstance(
      {
        onRefreshTokenInvalid: () => onRefreshTokenInvalid.current(),
      },
      () => getToken.current() // Pass the getToken function here
    )
  );

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        dispatch(
          authSlice.actions.update({
            user: {
              id: currentUser.uid,
              name: currentUser.displayName ?? '',
              email: currentUser.email ?? '',
            },
          })
        );
      } else {
        setUser(null);
        dispatch(
          authSlice.actions.update({
            user: null,
          })
        );
      }
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  const value = useMemo(
    () => ({
      user,
      getToken: () => getToken.current(),
      axiosClient: axiosClient.current,
    }),
    [user]
  );

  return (
    <AuthContext.Provider value={value}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
